import React from 'react'
import './blog.css'
import { Article } from '../../components'
import { blogImg } from './imports'

const Blog = () => {
	return (
		<div className="gpt3__blog section__padding" id="blog">
			<div className="gpt3__blog-hading">
				<h1 className="gradient__text">
					A lot is happening, <br /> We are blogging about it.
				</h1>
			</div>
			<div className="gpt3__blog-container">
				<div className="gpt3__blog-container_groupA">
					<Article
						imgUrl={blogImg.blog01}
						date="Sep 26, 2021"
						text="GPT-3 and Open  AI is the future. Let us exlore how it is?"
					/>
				</div>
				<div className="gpt3__blog-container_groupB">
					<Article
						imgUrl={blogImg.blog02}
						date="Sep 26, 2021"
						text="GPT-3 and Open  AI is the future. Let us exlore how it is?"
					/>
					<Article
						imgUrl={blogImg.blog03}
						date="Sep 26, 2021"
						text="GPT-3 and Open  AI is the future. Let us exlore how it is?"
					/>
					<Article
						imgUrl={blogImg.blog04}
						date="Sep 26, 2021"
						text="GPT-3 and Open  AI is the future. Let us exlore how it is?"
					/>
					<Article
						imgUrl={blogImg.blog05}
						date="Sep 26, 2021"
						text="GPT-3 and Open  AI is the future. Let us exlore how it is?"
					/>
				</div>
			</div>
		</div>
	)
}

export default Blog
