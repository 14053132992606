import React from 'react'
import './brand.css'
import imgs from './imports'

const Brand = () => {
	return (
		<div className="gpt3__brand section__padding">
			{imgs.map((img) => (
				<div key={img.key}>
					<img src={img} alt={img.value} />
				</div>
			))}
			{/* <div> */}
			{/* 	<img src={img.google} alt="google" /> */}
			{/* </div> */}
			{/* <div> */}
			{/* 	<img src={img.slack} alt="slack" /> */}
			{/* </div> */}
			{/* <div> */}
			{/* 	<img src={img.atlassian} alt="atlassian" /> */}
			{/* </div> */}
			{/* <div> */}
			{/* 	<img src={img.dropbox} alt="dropbox" /> */}
			{/* </div> */}
			{/* <div> */}
			{/* 	<img src={img.shopify} alt="shopify" /> */}
			{/* </div> */}
		</div>
	)
}

export default Brand
