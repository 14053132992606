import blog01 from '../../assets/blog01.png'
import blog02 from '../../assets/blog02.png'
import blog03 from '../../assets/blog03.png'
import blog04 from '../../assets/blog04.png'
import blog05 from '../../assets/blog05.png'

export const blogImg = {
	blog01,
	blog02,
	blog03,
	blog04,
	blog05,
}
